import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    document.addEventListener('keydown', this.checkKey.bind(this));

    this.reset();
  }

  disconnect() {
    document.removeEventListener('keydown', this.checkKey.bind(this));
  }

  checkKey(e) {
    const k = e.which;

    const elements = document.querySelectorAll('[data-results]');
    const n = elements.length;

    // Results have changed, therefore reset counter.
    const results = this.getResultsId();
    if (results && results !== this.resultIdCache) this.reset();

    // Search /intros/search on ENTER
    if (k === 13) {
      window.location = `/intros/search?query=${this.inputTarget.value}`;
      return;
    }

    // Iterate cyclically through results using up/down arrows.
    if (k === 38 || k === 40) {
      const r = k === 40 ? this.i += 1 : this.i -= 1;

      this.i = r < 0 ? n - 1 : this.i % n;

      elements[this.i].focus();
    } else {
      this.inputTarget.focus();
    }
  }

  getResultsId() {
    const results = document.querySelector('[data-results-id]');
    if (results) return results.dataset.resultsId;
    return null;
  }

  reset() {
    this.i = -1; // -1 so first result will be focused on first attempt
    this.resultIdCache = this.getResultsId();
  }
}
