import 'core-js';
import 'regenerator-runtime/runtime';

import '@hotwired/turbo-rails';
import 'focus-visible';
import 'focus-within';

import Bugsnag from '@bugsnag/js';
import Rails from '@rails/ujs';

import 'controllers/apply';
import 'controllers/shared';

require.context('../images', true);

if (process.env.RAILS_ENV === 'production') Bugsnag.start('c2342e4787496b43259dea0c9fcf5c21');

Rails.start();
