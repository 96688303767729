import { Controller } from '@hotwired/stimulus';
import { isEqual } from 'lodash';

export default class extends Controller {
  static targets = ['input', 'save', 'submitForReview', 'status'];

  static values = {
    submitting: String,
    submitted: String,
    submissionDelay: { type: Number, default: 3000 },
    ignoredFormFields: { type: Array, default: ['_method', 'authenticity_token'] },
  };

  static classes = ['animation'];

  connect() {
    this.isSubmitting = false;
    this.form = new FormData(this.element);
    setInterval(this.submitIfDirty, this.submissionDelayValue);
  }

  notifyFormSubmitStarted() {
    this.isSubmitting = true;
    this.submitForReviewTarget.disabled = true;
    this.statusTarget.classList.remove(this.animationClass);
    this.statusTarget.innerText = this.submittingValue;
  }

  notifyFormSubmitted() {
    this.isSubmitting = false;
    this.submitForReviewTarget.disabled = false;
    this.statusTarget.innerText = this.submittedValue;
    this.statusTarget.classList.add(this.animationClass);
  }

  submitIfDirty = () => {
    // Initialize instance variable for the first time
    if (this.form === undefined) {
      this.form = new FormData(this.element);
      return;
    }

    if (!this.isSubmitting && this.isDirty()) {
      requestAnimationFrame(() => this.element.requestSubmit(this.saveTarget));
    }

    this.form = new FormData(this.element);
  };

  isDirty = () => {
    const currentForm = new FormData(this.element);
    const previousFormKeyIterator = this.form.keys();
    const currentFormKeyIterator = currentForm.keys();

    let result = false;
    let { done: previousDone, value: prevKeyValue } = previousFormKeyIterator.next();
    let { done: currentDone } = currentFormKeyIterator.next();

    while (!previousDone) {
      if (
        currentDone ||
        !(
          this.ignoredFormFieldsValue.includes(prevKeyValue) ||
            (isEqual(this.form.getAll(prevKeyValue), currentForm.getAll(prevKeyValue)))
        )
      ) {
        result = true;
        break;
      }

      ({ done: previousDone, value: prevKeyValue } = previousFormKeyIterator.next());
      ({ done: currentDone } = currentFormKeyIterator.next());
    }

    if (!currentDone) { result = true; }

    return result;
  };
}
