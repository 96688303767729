import { Controller } from '@hotwired/stimulus';
import { debounce } from 'lodash';

export default class extends Controller {
  connect() {
    this.search = debounce(this.search, 200).bind(this);
  }

  search() {
    this.element.requestSubmit();
  }
}
