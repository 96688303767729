import { Controller } from '@hotwired/stimulus';

// This is similar to the nested forms controller but instead of allowing users
// to click add/remove this expects a select to define how many nested forms
// should be displayed
export default class extends Controller {
  static targets = ['select', 'target', 'template'];

  static values = { wrapperSelector: String };

  initialize() {
    this.select = this.selectTarget;
    this.target = this.targetTarget;
    this.template = this.templateTarget;
    this.wrapperSelector = this.wrapperSelectorValue || '.nested-form-select-wrapper';
    this.updateNestedForms = this.updateNestedForms.bind(this);
    this.select.addEventListener('change', this.updateNestedForms);
  }

  updateNestedForms() {
    const selectValue = this.select.value;
    const currentFormsCount = this.nestedFormsCount();

    if (selectValue < currentFormsCount) {
      for (let i = 0; i < currentFormsCount - selectValue; i += 1) this.remove();
    } else if (selectValue > currentFormsCount) {
      for (let i = 0; i < selectValue - currentFormsCount; i += 1) this.add();
    }
  }

  add() {
    const childIndex = this.nextChildIndex();
    const wrapper = this.element.querySelector(
      `${this.wrapperSelector}[data-index="${childIndex}"]`,
    );
    if (wrapper) {
      wrapper.style.removeProperty('display');
      delete wrapper.dataset.nestedFormRemoved;
      wrapper.querySelector("input[name*='_destroy']").value = 0;
    } else {
      const content = this.template.innerHTML
        .replace(/CHILD_INDEX_PLUS_ONE/g, childIndex + 1)
        .replace(/CHILD_INDEX/g, childIndex);

      this.target.insertAdjacentHTML('beforebegin', content);
    }
  }

  nextChildIndex() {
    return this.nestedFormsCount();
  }

  remove() {
    const wrapper = Array.from(this.element.querySelectorAll(
      `${this.wrapperSelector}:not([data-nested-form-removed='true'])`,
    )).pop();

    wrapper.style.display = 'none';
    wrapper.dataset.nestedFormRemoved = 'true';
    wrapper.querySelector("input[name*='_destroy']").value = 1;
  }

  nestedFormsCount() {
    const visibleForms = this.element.querySelectorAll(
      `${this.wrapperSelector}:not([data-nested-form-removed='true'])`,
    ).length;

    return visibleForms;
  }
}
