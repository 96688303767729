import NestedForm from 'stimulus-rails-nested-form';

export default class extends NestedForm {
  static targets = ['count', 'row', 'index', 'answerNo'];

  add(event) {
    super.add(event);
    this.setIndices();
  }

  remove(event) {
    event.preventDefault();

    const wrapper = event.target.closest(this.wrapperSelectorValue);
    const { index } = wrapper.dataset;
    const idSelector = `input[name='application[investments_attributes][${index}][id]']`;

    if (document.querySelector(idSelector).value) {
      const selector = `input[name='application[investments_attributes][${index}][_destroy]']`;
      document.querySelector(selector).value = 'true';
    }

    wrapper.remove();

    this.setIndices();

    if (!this.rowTargets.length && this.hasAnswerNoTarget) {
      this.answerNoTarget.click();
    }
  }

  setIndices() {
    this.indexTargets.forEach((element, index) => {
      // eslint-disable-next-line no-param-reassign
      element.value = index;
    });
  }

  ensureInvestmentFields(event) {
    if (this.rowTargets.length) { return; }
    this.add(event);
  }
}
