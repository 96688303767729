import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['target', 'template', 'addActionWrapper', 'removeActionWrapper'];

  static values = { min: Number, max: Number, wrapperSelector: String };

  initialize() {
    this.min = this.minValue === 0 ? null : this.minValue;
    this.max = this.maxValue === 0 ? null : this.maxValue;
    this.target = this.targetTarget;
    this.template = this.templateTarget;
    this.wrapperSelector = this.wrapperSelectorValue || '.nested-form-wrapper';
    this.toggleActionWrappers();
  }

  add(e) {
    e.preventDefault();
    document.activeElement.blur();

    if (typeof this.max === 'number' && this.nestedFormsCount() >= this.max) return;

    const content = this.template.innerHTML.replace(/CHILD_INDEX/g, this.nestedFormsCount() + 1);

    this.target.insertAdjacentHTML('beforebegin', content);

    this.setLabels();
    this.toggleActionWrappers();
    this.focusFirstField();
  }

  focusFirstField() {
    const forms = Array.from(
      this.element.querySelectorAll(`:scope ${this.wrapperSelector}`),
    );
    const newForm = forms.pop();
    newForm?.querySelector('input[data-focus]')?.focus();
  }

  remove(e) {
    e.preventDefault();
    document.activeElement.blur();

    if (typeof this.min === 'number' && this.nestedFormsCount() <= this.min) return;

    const wrapper = e.target.closest(this.wrapperSelector);

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove();
    } else {
      wrapper.style.display = 'none';
      wrapper.dataset.nestedFormRemoved = 'true';
      wrapper.querySelector("input[name*='_destroy']").value = 1;
    }

    this.setLabels();
    this.toggleActionWrappers();
  }

  toggleActionWrappers() {
    if (typeof this.max === 'number') {
      if (this.nestedFormsCount() < this.max) {
        this.addActionWrapperTarget.style.display = null;
      } else {
        this.addActionWrapperTarget.style.display = 'none';
      }
    }

    if (typeof this.min === 'number') {
      const toggler = this.nestedFormsCount() > this.min
        ? this.showActionWrapper
        : this.hideActionWrapper;

      this.removeActionWrapperTargets.forEach(toggler);
    }
  }

  showActionWrapper(actionWrapper) {
    actionWrapper.style.display = null; // eslint-disable-line no-param-reassign
  }

  hideActionWrapper(actionWrapper) {
    actionWrapper.style.display = 'none'; // eslint-disable-line no-param-reassign
  }

  nestedFormsCount() {
    const visibleFormsLength = this.element.querySelectorAll(
      `${this.wrapperSelector}:not([data-nested-form-removed='true'])`,
    ).length;

    return visibleFormsLength;
  }

  setLabels() {
    const visibleForms = this.element.querySelectorAll(
      `${this.wrapperSelector}:not([data-nested-form-removed='true'])`,
    );
    visibleForms.forEach((form, index) => {
      const label = form.getAttribute('data-nested-forms-label-placeholder');
      form.querySelector('label').innerHTML = label.replace(/INDEX_PLACEHOLDER/g, index + 1); // eslint-disable-line no-param-reassign
    });
  }
}
