import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['indicator', 'input', 'inputWrapper'];

  static values = {
    limit: Number,
    overLimitWrapperClass: String,
    overLimitIndicatorClass: String,
  };

  initialize() {
    this.indicator = this.indicatorTarget;
    this.input = this.inputTarget;
    this.inputWrapper = this.inputWrapperTarget;

    this.limit = this.limitValue || 140;
    this.overLimitIndicatorClass = (this.overLimitIndicatorClassValue || 'text-red-500').split(' ');
    this.overLimitWrapperClass = (this.overLimitWrapperClassValue || 'has-error').split(' ');
  }

  connect() {
    this.input.addEventListener('input', this.updateIndicator.bind(this));
    this.updateIndicator();
  }

  updateIndicator() {
    const inputValueLength = this.input.value.length;
    const charsRemaining = this.limit - inputValueLength;

    this.indicator.innerHTML = `${inputValueLength}/${this.limit}`;

    this.overLimitWrapperClass.forEach((clas) => {
      if (charsRemaining < 0) {
        this.inputWrapper.classList.add(clas);
      } else {
        this.inputWrapper.classList.remove(clas);
      }
    });
    this.overLimitIndicatorClass.forEach((clas) => {
      if (charsRemaining < 0) {
        this.indicator.classList.add(clas);
      } else {
        this.indicator.classList.remove(clas);
      }
    });
  }
}
