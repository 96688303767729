import { Controller } from '@hotwired/stimulus';
import { useDispatch } from 'stimulus-use';

export default class extends Controller {
  static targets = ['disclosure'];

  static classes = ['hide'];

  static values = {
    revealOn: String,
  };

  connect() {
    useDispatch(this, { element: this.disclosureTarget });
  }

  toggle({ target: { value } }) {
    if (value === this.revealOnValue) {
      this.disclosureTarget.classList.remove(this.hideClass);
      this.dispatch('reveal');
      return;
    }

    this.disclosureTarget.classList.add(this.hideClass);
    this.dispatch('hide');
  }
}
