import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'form'];

  connect() {
    if (this.hasInputTarget) this.inputTarget.focus();
  }

  mobileFocus() {
    if (this.formTarget) this.formTarget.requestSubmit();
  }
}
