import NestedForm from 'stimulus-rails-nested-form';

export default class extends NestedForm {
  static targets = ['add', 'remove', 'section', 'template', 'index', 'title'];

  static classes = ['hide'];

  connect() {
    this.showLastAddTarget();
  }

  add(event) {
    this.hideAddTargets();
    super.add(event);
    this.lastSection.scrollIntoView();
    this.setIndices();
    this.setTitle();
  }

  remove(event) {
    event.preventDefault();

    const wrapper = event.target.closest('.card-body');
    const { index } = wrapper.dataset;
    const idSelector = `input[name='application[founder_infos_attributes][${index}][id]']`;

    if (document.querySelector(idSelector).value) {
      const selector = `input[name='application[founder_infos_attributes][${index}][_destroy]']`;
      document.querySelector(selector).value = 'true';
    }

    event.target.closest('.card-body').remove();
    this.showLastAddTarget();
    this.setIndices();
    this.setTitle();
  }

  hideAddTargets() {
    this.addTargets.forEach((element) => {
      element.classList.add(this.hideClass);
    });
  }

  setIndices() {
    this.indexTargets.forEach((element, index) => {
      // eslint-disable-next-line no-param-reassign
      element.value = index;
    });
  }

  showLastAddTarget() {
    this.hideAddTargets();
    if (this.lastAddTarget) {
      this.lastAddTarget.classList.remove(this.hideClass);
    }
  }

  setTitle() {
    if (this.titleTargets.length === 1) {
      this.titleTarget.innerText = 'Founder Contact';
      return;
    }

    this.titleTargets.forEach((title) => {
      // eslint-disable-next-line no-param-reassign
      title.innerText = 'Co-Founder Contact';
    });
  }

  get lastAddTarget() {
    return this.addTargets.at(-1);
  }

  get lastSection() {
    return this.sectionTargets.at(-1);
  }
}
