import Flatpickr from 'stimulus-flatpickr';
import MonthSelect from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/plugins/monthSelect/style.css';

export default class extends Flatpickr {
  connect() {
    this.dateFormat = this.dateFormatValue || 'm/Y';
    this.config = {
      plugins: [
        new MonthSelect({ shorthand: false, dateFormat: this.dateFormat }),
      ],
    };

    super.connect();
  }
}
