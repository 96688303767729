import NestedForm from 'stimulus-rails-nested-form';

export default class extends NestedForm {
  static targets = ['row', 'add', 'remove', 'answerNo'];

  static classes = ['hide'];

  static values = {
    maxRows: { type: Number, default: 6 },
  };

  rowTargetConnected(element) {
    if (this.rowTargets.length === this.maxRowsValue) {
      this.addTarget.classList.add(this.hideClass);
    }

    if (element.dataset.newRecord === 'false') {
      return;
    }

    const prevRow = this.rowTargets[this.rowTargets.length - 2];
    if (!prevRow) {
      return;
    }

    const prevMonthIndex = prevRow.querySelector('select').selectedIndex;
    let currentMonthIndex;

    switch (prevMonthIndex) {
      case 0:
        currentMonthIndex = 11;
        break;
      default:
        currentMonthIndex = prevMonthIndex - 1;
        break;
    }

    // eslint-disable-next-line no-param-reassign
    element.querySelector('select').selectedIndex = currentMonthIndex;
    const options = element.querySelectorAll('option');

    this.rowTargets
      .slice(0, -1)
      .map((row) => row.querySelector('select').selectedIndex)
      .forEach((index) => { options[index].disabled = true; });
  }

  rowTargetDisconnected() {
    if (this.rowTargets.length < this.maxRowsValue) {
      this.addTarget.classList.remove(this.hideClass);
    }
  }

  add(event) {
    if (this.rowTargets.length < this.maxRowsValue) {
      super.add(event);
    }
  }

  remove(event) {
    document.querySelector(event.params.destroyReference).value = true;
    event.target.parentElement.remove();

    if (!this.rowTargets.length && this.hasAnswerNoTarget) {
      this.answerNoTarget.click();
    }
  }

  ensureMonthFields(event) {
    if (this.rowTargets.length) { return; }

    this.add(event);
  }
}
