import { Controller } from '@hotwired/stimulus';
import { useThrottle } from 'stimulus-use';

export default class extends Controller {
  static throttles = ['format'];

  static values = {
    max: Number,
  };

  connect() {
    useThrottle(this, { wait: 100 });
  }

  format({ target }) {
    const { value } = target;

    const rawValue = value.replace(/[^\d.-]/g, '');

    if (!rawValue || rawValue[rawValue.length - 1] === '.') { return; }

    // eslint-disable-next-line no-param-reassign
    target.value = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 0,
    }).format(rawValue);
  }
}
